<template>
  <HCard>
    <div id="updatePasswordTemplate">
      <p v-if="error" class="error">{{ error }}</p>
      <div v-if="checkNewPassword(0)" class="tw-w-1/2">
        <p class="infoChar">Votre mot de passe doit remplir ces 5 conditions :</p>
        <ul class="specialChar">
          <li v-bind:class="[ validation[0].hasMinuscule ? 'valid' : 'validation-error']"><span>a</span><br>minuscule</li>
          <li v-bind:class="[ validation[0].hasMajuscule ? 'valid' : 'validation-error']"><span>A</span><br>majuscule</li>
          <li v-bind:class="[ validation[0].hasSpecial ? 'valid' : 'validation-error']"><span>#</span><br>caractère spécial</li>
          <li v-bind:class="[ validation[0].hasNumber ? 'valid' : 'validation-error']"><span>1</span><br>chiffre</li>
          <li v-bind:class="[ validation[0].hasMore ? 'valid' : 'validation-error']"><span>8+</span><br>caractères</li>
        </ul>
      </div>
      <label for="password1">Ancien mot de passe :</label>
        <VuePassword
            id="password1"
            v-model="validation[0].password"
            :class="checkConditionsClass(0)"
            :disableStrength="true"
            class="tw-w-1/2 mb-base"
            placeholder=""
            type="password"
            v-on:keyup="checkConditions(1)"
            data-cy="old-password-field"
        />
      <div v-if="checkNewPassword(1)" class="tw-w-1/2">
        <p class="infoChar">Votre mot de passe doit remplir ces 5 conditions :</p>
        <ul class="specialChar">
          <li v-bind:class="[ validation[1].hasMinuscule ? 'valid' : 'validation-error']"><span>a</span><br>minuscule</li>
          <li v-bind:class="[ validation[1].hasMajuscule ? 'valid' : 'validation-error']"><span>A</span><br>majuscule</li>
          <li v-bind:class="[ validation[1].hasSpecial ? 'valid' : 'validation-error']"><span>#</span><br>caractère spécial</li>
          <li v-bind:class="[ validation[1].hasNumber ? 'valid' : 'validation-error']"><span>1</span><br>chiffre</li>
          <li v-bind:class="[ validation[1].hasMore ? 'valid' : 'validation-error']"><span>8+</span><br>caractères</li>
        </ul>
      </div>
      <label for="password2">Nouveau mot de passe :</label>
        <VuePassword
            id="password2"
            v-model="validation[1].password"
            :class="checkConditionsClass(1)"
            :disableStrength="true"
            class="tw-w-1/2 mb-base"
            placeholder=""
            type="password"
            v-on:keyup="checkConditions(2)"
            data-cy="password-field"
        />
      <div v-if="checkNewPassword(2)" class="tw-w-1/2">
        <p class="infoChar">Votre mot de passe doit remplir ces 6 conditions :</p>
        <ul class="specialChar last">
          <li v-bind:class="[ validation[2].hasMinuscule ? 'valid' : 'validation-error']"><span>a</span><br>minuscule</li>
          <li v-bind:class="[ validation[2].hasMajuscule ? 'valid' : 'validation-error']"><span>A</span><br>majuscule</li>
          <li v-bind:class="[ validation[2].hasSpecial ? 'valid' : 'validation-error']"><span>#</span><br>caractère spécial</li>
          <li v-bind:class="[ validation[2].hasNumber ? 'valid' : 'validation-error']"><span>1</span><br>chiffre</li>
          <li v-bind:class="[ validation[2].hasMore ? 'valid' : 'validation-error']"><span>8+</span><br>caractères</li>
          <li v-bind:class="[ validation[2].password === validation[1].password && validation[1].password != '' ? 'valid' : 'validation-error']">La
            confirmation<br>ne correspond pas
          </li>
        </ul>
      </div>
      <label for="password3">Confirmation du nouveau mot de passe :</label>
        <VuePassword
            id="password3"
            v-model="validation[2].password"
            :class="checkConditionsClass(2)"
            :disableStrength="true"
            class="tw-w-1/2 mb-base"
            placeholder=""
            type="password"
            v-on:keyup="checkConditions(2)"
            data-cy="password-confirm-field"
        />
      <div class="flex flex-wrap items-center justify-end">
        <vs-button
            :disabled="formIsValid()"
            class="ml-auto mt-2"
            @click="updatePassword()"
            data-cy="save-password-btn"
        >Sauvegarder</vs-button>
      </div>
    </div>
</HCard>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { Base64 } from 'js-base64'
import Storage from '@/storage'
import appSettings from '@/appsettings'
import Regexes from '@/constants/regexes'
import VuePassword from 'vue-password'

import {
    HCard
} from '@happytal/bo-ui-library'

export default {
  data() {
    return {
      error: null,
      old_password: '',
      new_password: '',
      confirm_new_password: '',
      validation: [
        [{
          password: '',
          hasMinuscule: false,
          hasMajuscule: false,
          hasSpecial: false,
          hasNumber: false,
          hasMore: false
        }
        ],
        [{
          password: '',
          hasMinuscule: false,
          hasMajuscule: false,
          hasSpecial: false,
          hasNumber: false,
          hasMore: false
        }
        ],
        [{
          password: '',
          hasMinuscule: false,
          hasMajuscule: false,
          hasSpecial: false,
          hasNumber: false,
          hasMore: false
        }
        ],
      ]
    }
  },
  components: {
    VuePassword,
    HCard
  },
  methods: {
    showToast(text) {
      this.$toasted.show(text, {
        theme: 'hx-toasted',
        position: 'hx-bottom-left',
        duration: null,
        action: [
          {
            text: 'Fermer',
            onClick: (e, to) => {
              to.goAway(0)
            }
          }
        ]
      })
    },
    checkConditionsClass(index) {
      if (this.validation[index] && this.validation[index].password && this.validation[index].password.length > 0) {
        if (this.validation[index].hasMore && this.validation[index].hasMinuscule && this.validation[index].hasMajuscule && this.validation[index].hasSpecial && this.validation[index].hasNumber) {
          if (index === 2) {
            if (this.validation[1].password === this.validation[2].password) {
              return 'valid'
            } else {
              return 'invalid'
            }
          } else {
            return 'valid'
          }
        } else {
          return 'invalid'
        }
      } else {
        return ''
      }
    },
    checkConditions(index) {
      if (this.validation[index] && this.validation[index].password && this.validation[index].password.length > 0) {
        this.validation[index].hasMore = this.validation[index].password.length >= 8
        this.validation[index].hasMinuscule = Regexes.Minuscule.test(this.validation[index].password)
        this.validation[index].hasMajuscule = Regexes.Majuscule.test(this.validation[index].password)
        this.validation[index].hasSpecial = this.validation[index].password && !Regexes.AlphaNumeric.test(this.validation[index].password)
        this.validation[index].hasNumber = Regexes.Number.test(this.validation[index].password)
        if (this.validation[index].hasMore && this.validation[index].hasMinuscule && this.validation[index].hasMajuscule && this.validation[index].hasSpecial && this.validation[index].hasNumber) {
          if (index === 2) {
            if (this.validation[1].password === this.validation[2].password) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    checkNewPassword(index) {
      if (this.validation[index] && this.validation[index].password && this.validation[index].password.length > 0 && !this.checkConditions(index)) {
        return true;
      }
    },
    formIsValid() {
      if (this.checkConditions(0) && this.checkConditions(1) && this.checkConditions(2)) {
        return false;
      } else {
        return true;
      }
    },
    updatePassword: function () {
      if (this.validation[1].password === this.validation[2].password) {
        const user = Storage.getCurrentUser()
        const userGuid = _.get(user, 'auth.sub')
        const token = _.get(user, 'auth.access_token')
        axios({
          method: 'put',
          url: appSettings.VUE_APP_USER_API + '/' + userGuid + '/password',
          data: {
            password: Base64.encode(this.validation[1].password)
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'current-password': Base64.encode(this.validation[0].password)
          }
        }).then(response => {
          console.log(response)
          if (response.data.succeeded === false) {
            if (response.data.errors[0].code === 'PasswordMismatch') {
              this.showToast("Votre ancien mot de passe est invalide")
            }
          } else {
            this.showToast("Mot de passe modifié avec succès")
            this.validation[0].password = '';
            this.validation[1].password = '';
            this.validation[2].password = '';
          }
        }).catch(error => {
          this.showToast("Une erreur est survenue")
          this.error = error
        })
      } else {
        this.error = ''
      }
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.dataUser.user
    }
  }
}
</script>

<style lang="scss">

#updatePasswordTemplate {
  .validation-error {
    color: red;
    margin: 0 0 20px 0;
  }

  p.infoChar {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  ul.specialChar {
    width: 100%;

    &.last {
      li {
        width: 16%;
      }
    }

    li {
      width: 20%;
      display: inline-block;
      text-align: center;
      font-size: 12px;

      span {
        font-size: 18px;
      }

    }
  }

  li.error {
    color: #EA5455;
  }

  li.valid {
    color: #4CAF50;
  }

  .VuePassword__Input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: .7rem !important;
    font-size: 1rem !important;
    border-radius: 5px;
  }

  .VuePassword__Toggle {
    cursor: pointer;
  }

  .valid .VuePassword__Input {
    border-color: green;
  }

  .invalid .VuePassword__Input {
    border-color: red;
  }

  .mb-base {
    margin-bottom: 1.2rem !important;
  }
}
</style>
