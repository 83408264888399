<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'Informations' : ''">
      <div class="tab-general md:tw-ml-4 md:tw-mt-0 mt-4 ml-0">
        <user-settings-general />
      </div>
    </vs-tab>
    <vs-tab
        icon-pack="feather"
        icon="icon-lock"
        :label="!isSmallerScreen ? 'Mot de passe' : ''"
        data-cy="password-tab"
    >
      <div class="tab-change-pwd md:tw-ml-4 md:tw-mt-0 mt-4 ml-0">
        <user-settings-change-password />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSettingsChangePassword from './UserSettingsChangePassword.vue'

export default {
  components: {
    UserSettingsGeneral,
    UserSettingsChangePassword,
  },
  data () {
    return {

    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
  .vs-tabs--btn {
    z-index: 0;
  }
}
.h-menu-item.v-list-item--active[title=Compte] {
  .h-icon {
    color: #22292f !important;
  }
}
</style>
