<template>
    <HCard>
        <vs-input
            class="tw-w-full tw-mb-base"
            label-placeholder="Prénom"
            v-model="first_name"
            disabled="disabled"
            data-cy="firstName-field"
        ></vs-input>
        <vs-input
            class="tw-w-full tw-mb-base"
            label-placeholder="Nom"
            v-model="last_name"
            disabled="disabled"
            data-cy="lastName-field"
        ></vs-input>
        <vs-input
            class="tw-w-full"
            label-placeholder="Email"
            v-model="email"
            disabled="disabled"
            data-cy="email-field"
        ></vs-input>
        <vs-input
            class="tw-w-full tw-my-base"
            label-placeholder="Site"
            v-model="site"
            disabled="disabled"
            data-cy="site-field"
        ></vs-input>
        <vs-input
            class="tw-w-full tw-my-base"
            label-placeholder="Rôle"
            v-model="role"
            disabled="disabled"
            data-cy="role-field"
        ></vs-input>
        <vs-checkbox
            v-model="consentement"
            data-cy="communication-consent-check"
        > J’accepte de recevoir des communications et propositions de produits ou de services relatifs à happydom</vs-checkbox>
        <vs-checkbox
            v-if="isTutorOrFamily"
            v-model="suggestionsConsent"
            class="mt-4"
            data-cy="suggestion-consent-check"
        >{{ textOptinSuggestion }}</vs-checkbox>
        <div class="flex flex-wrap items-center justify-end">
            <vs-button
                class="ml-auto mt-6"
                @click="onSaveBtnClick"
                data-cy="save-general-btn"
            >Sauvegarder</vs-button>
        </div>
    </HCard>
</template>

<script>
import _ from 'lodash'

import {
    HCard
} from '@happytal/bo-ui-library'

export default {
    components: {
        HCard
    },
    data () {
        return {
            first_name: _.get(this.$store, 'state.dataUser.user.firstName', '-'),
            last_name: _.get(this.$store, 'state.dataUser.user.lastName', '-'),
            email: _.get(this.$store, 'state.dataUser.user.email', '-'),
            role: _.get(this.$store, 'state.dataUser.user.displayRole', '-'),
            consentement: _.get(this.$store, 'state.dataUser.user.acceptCommunications', false),
            suggestionsConsent: this.$store.getters['dataUser/getSuggestionsNotificationsEnabled'](),
            site: '-'
        }
    },
    computed: {
        isTutorOrFamily() {
            return this.$aclChecker.some(this.$acl, ['isTutor', 'isFamily'])
        },
        textOptinSuggestion() {
            if (this.$acl.check('isTutor')) {
                return "J’accepte de recevoir un mail de notification 1 fois par semaine, envoyé uniquement quand j’ai de nouvelles suggestions à traiter"
            } else if (this.$acl.check('isFamily')) {
                return "J’accepte de recevoir un mail de notification 1 fois par jour, envoyé uniquement quand j’ai de nouvelles suggestions à traiter"
            }
            return ""
        },
    },
    methods: {
        getInstitutionById (institutionId) {
            return this.$store.getters['dataInstitutions/getInstitutionById'](institutionId)
        },
        getUserInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getUserInstitutionLabel () {
            const institutionId = this.getUserInstitutionId()
            const institution = this.getInstitutionById(institutionId)
            return institution ? institution.name : '-'
        },
        async onSaveBtnClick () {
            try {
                await this.$store.dispatch('dataUser/updateConsent', { acceptCommunications: this.consentement })
                await this.$store.dispatch('dataUser/updateSuggestionsConsent', this.suggestionsConsent)
                this.showToast("Profil mis à jour")
            } catch (error) {
                this.showToast("Une erreur s'est produite, le profil n'a pas pu être mis à jour")
            }
        },
        showToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : 3000,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
    },
    mounted () {
        if (!this.$store.state.dataInstitutions.isRegistered) {
            this.$store.dispatch('dataInstitutions/getInstitutions')
        }
        this.site = this.getUserInstitutionLabel()
    }
}
</script>
